import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrManagePromoCodesForm',
  computed: {
    ...mapState('registration', ['registrationFees'])
  }
})
export default class GtrManagePromoCodesForm extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

  @Ref()
  readonly observerNewUserCompanyForm!: InstanceType<typeof ValidationObserver>;

  data () {
    return {
      submitting: false,
      showForm: false,
      promo_code: null
    }
  }

  async mounted () {
    if (!this.$store.state.registration.registrationFees) {
      await this.fetchRegistrationFees()
    }
  }

  @Watch('visible', { immediate: true })
  onVisibleValueChange (newVisibleValue: boolean) {
    this.$data.showForm = newVisibleValue
  }

  private async fetchRegistrationFees () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchEventFields () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async applyPromoCode () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        data: {
          code: this.$data.promo_code,
          participant_uuid: this.$route.params.attendee_uuid
        }
      }
      await this.$store.dispatch('registration/applyPromoCode', payload)
      Container.get(Notification).success('Promo code successfully applied.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async removePromoCode () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        data: {
          participant_uuid: this.$route.params.attendee_uuid
        }
      }
      await this.$store.dispatch('registration/removePromoCode', payload)
      Container.get(Notification).success('Promo code successfully removed.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
